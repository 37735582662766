import IExtension from "./IExtension";
import {Naja} from "naja";

export default class SubmittedFormsExtension implements IExtension {

    private submittedForms = {};

    public initialize(naja: Naja): void {
        naja.addEventListener('init', () => this.init($('body')));
        naja.snippetHandler.addEventListener('afterUpdate', (e) => this.init($(e.detail.snippet)));
        naja.addEventListener('success', () => this.reset());
    }

    private init($root): void {
        $root.find('form[data-prevent-double-submit]').on('submit', e => {
            const id = e.currentTarget.id || 1;
            if (this.submittedForms[id]) {
                e.preventDefault();
            }

            this.submittedForms[id] = true;
        })
    }

    private reset(): void {
        this.submittedForms = {};
    }


}