import IExtension from "./IExtension";
import {Naja} from "naja";

/**
 * Add spinner to element, which interaction fires ajax request
 * not input[type="submit"]
 */
export default class AjaxSpinnersExtension implements IExtension {

    private $interactedElement: JQuery<Element>|null = null;

    public initialize(naja: Naja): void {
        naja.uiHandler.addEventListener('interaction', e => {
            this.$interactedElement = $(e.detail.element);
        });
        naja.addEventListener('before', () => {
            if (this.$interactedElement) {
                this.addSpinner(this.$interactedElement);
                this.$interactedElement = null;
            }
        });
        naja.addEventListener('complete', () => {
            this.removeAllSpinners();
            this.$interactedElement = null;
        });
    }

    private removeAllSpinners(): void {
        $('[data-submitted-form-spinner]').each((i, spinner) => {
           $(spinner).parent().removeClass('disabled');
        }).remove();
    }

    private addSpinner($el: JQuery<Element>): void {

        let elementSpinnerTarget = $el;
        if ($el.data('ajax-loader-target')) {
            elementSpinnerTarget = $('#' + $el.data('ajax-loader-target'));
        }

        elementSpinnerTarget.addClass('disabled');
        if (elementSpinnerTarget.find('[data-submitted-form-spinner]').length) {
            return;
        }
        elementSpinnerTarget.prepend($('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" data-submitted-form-spinner></span>'));
    }

}
