import BaseComponent from '../../BaseComponent';
import Swal from "sweetalert2";

export default class DiscussionFormConfirmation extends BaseComponent {
    public init(): void {

        let beenConfirmed = false;
        const form = <HTMLFormElement>this.$component[0];
        form.addEventListener('submit', e => {
            if (!window.Nette.validateForm(form)) {
                return;
            }

            const videoDateInput = e.target.elements['videos[0][video_date]'];
            const videoExternalUsersInput = e.target.elements['only_for_externals'];
            // const clients_selection_toggle = e.target.elements['clients_selection_toggle']; // uncomment if wanna apply for clients too
            const respondents_selection_toggle = e.target.elements['respondents_selection_toggle'];

            if (
                (videoDateInput && videoDateInput.value) // double check if is set videocall
                // && !clients_selection_toggle.checked // if is not restricted to clients
                && !respondents_selection_toggle.checked // if is not restricted to respondents
                && !videoExternalUsersInput.checked // if is not only for external respondents
            ) {
                if (!beenConfirmed) {
                    const cancelTranslation = e.submitter.getAttribute('data-cancel-translation');
                    const confirmTranslation = e.submitter.getAttribute('data-confirm-translation');
                    const add_all_users_to_videocall = e.submitter.getAttribute('data-confirm-question');

                    e.preventDefault();
                    Swal.fire({
                        text: add_all_users_to_videocall,
                        showCancelButton: true,
                        confirmButtonColor: "#6E65AE",
                        cancelButtonColor: "#6c757d",
                        cancelButtonText: cancelTranslation,
                        confirmButtonText: confirmTranslation
                    }).then((result) => {
                        if (result.isConfirmed) {
                            beenConfirmed = true;
                            e.submitter.click();
                        }
                    });
                }
            }
        });
    }

}