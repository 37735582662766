import IExtension from './IExtension';
import { Naja } from 'naja';
import netteForms from 'nette-forms';
window.Nette = netteForms;

declare global { // declare pages to window
    interface Window {
        Nette: {
            init: () => void,
            validateForm: (sender, onlyCheck) => boolean,
            recaptcha: any,
            initForm: (any) => void,
            toggle: (id: string, visible: boolean) => void,
            showFormErrors: (form, errors) => void,
            validateControl: (elem: Element, rules?: string[], onlyCheck?: boolean, value?: string, emptyOptional?: boolean) => boolean,
            formErrors: string[],
            validators: {[op: string]: (elem: HTMLInputElement, arg: any, val: string) => boolean}
        },
    }
}

window.Nette.toggle = function (id, visible) {
    if (id === 'gridgroup_action_submit') {
        return;
    }

    const $el = $('#' + id);
    if (visible) {
        $el.slideDown();
    } else {
        $el.slideUp();
    }
};

export default class NetteFormsExtension implements IExtension {
    public initialize (naja: Naja): void {
        netteForms.initOnLoad();
        this.initializeValidators();

        naja.addEventListener('init', () => this.init($('body')));
        naja.snippetHandler.addEventListener('afterUpdate', (e) => this.init($(e.detail.snippet)));
    }

    private init ($root): void {
        $root.find('form').each((i, form) => window.Nette.initForm(form));
        this.initializeNetteFormValidation($root);
    }

    private initializeValidators () {
        window.Nette.validators.AppFormsValidators_validateIsChecked = function (elem, arg, value) {
            return value.indexOf(arg) >= 0;
        };
    }

    private initializeNetteFormValidation ($root) {
        const getLabel = (element) => {
            const name = $(element).attr('name');

            if (!name) return $();

            let label = $("label[for='" + name + "']").not('.omit-form-errors'); // same name as for radios,  ...
            if (label.length === 0) {
                label = $("label[for='" + name.replace(/\[[^[]*]$/, '') + "']").not('.omit-form-errors'); // name contains [] on the end (checkboxes)
                if (label.length === 0) {
                    label = $("label[for='" + $(element).attr('id') + "']").not('.omit-form-errors'); // by id
                    if (label.length === 0) {
                        label = $(`label[for^='${$(element).attr('id')}']`).not('.omit-form-errors'); // by id
                    }
                }
            }
            if ($(element).parents('.checkboxList').length) {
                return $(element).parents('.checkboxList');
            }
            if ($(element).parents('.radio').length) {
                return $(element).parents('.radio');
            }

            return label;
        };

        /* Nette form validation */
        const showErrors = (errors, focus = false) => {
            errors.forEach(function (error) {
                if (error.message) {
                    removeErrors(error.element);
                    $(error.element).addClass('has-error').find('.error').remove();
                    getLabel(error.element)
                        .addClass('has-error')
                        .append('<span class="error-message"> (' + error.message + ')</span>')
                    ;
                }
                if (focus && error.element.focus) {
                    error.element.focus();
                    focus = false;
                }
            });
        };

        const removeErrors = (elem) => {
            if ($(elem).is('form')) {
                $('.has-error', elem).removeClass('has-error');
                $('.error', elem).remove();
            } else {
                $(elem).removeClass('has-error').find('.error').remove();
                getLabel(elem)
                    .removeClass('has-error')
                    .find('.error-message').remove();
            }
        };

        window.Nette.showFormErrors = function (form, errors) {
            removeErrors(form);
            showErrors(errors, true);
        };

        $root.find(':input').on('keypress change', function () {
            removeErrors(this);
        }).on('blur', (e) => {
            window.Nette.formErrors = [];
            window.Nette.validateControl(e.currentTarget);
            if (window.Nette.formErrors.length === 0) {
                removeErrors(e.currentTarget);
            }
            showErrors(window.Nette.formErrors);
        });

        $root.find('[data-addon-check]').on('change', function (e) {
            const addonInputCheckAttrs = $(e.currentTarget).attr('data-addon-check');
            if (addonInputCheckAttrs) {
                const addonInputCheckIds = addonInputCheckAttrs.split(',');
                if (addonInputCheckIds && addonInputCheckIds.length > 0) {
                    for (const index in addonInputCheckIds) {
                        window.Nette.formErrors = [];
                        const addonInputCheckElement = document.getElementById(addonInputCheckIds[index]);
                        window.Nette.validateControl(addonInputCheckElement);

                        // remove addon warning
                        let addonInputWarningElement;
                        const addonInputWarningAttribute = addonInputCheckElement.getAttribute('data-addon-warning');
                        console.log(addonInputWarningAttribute);
                        if (addonInputWarningAttribute) {
                            addonInputWarningElement = document.getElementById(addonInputWarningAttribute);
                            if (addonInputWarningElement) {
                                if (addonInputWarningElement.classList.contains('has-error')) {
                                    addonInputWarningElement.classList.remove('has-error');
                                }
                            }
                        }

                        if (window.Nette.formErrors.length === 0) {
                            removeErrors(addonInputCheckElement);
                        } else {
                            console.log(addonInputWarningElement);
                            // add addon warning info
                            if (addonInputWarningElement) {
                                if (!addonInputWarningElement.classList.contains('has-error')) {
                                    addonInputWarningElement.classList.add('has-error');
                                }
                            }
                        }
                        showErrors(window.Nette.formErrors);
                    }
                }
            }
        });
    }
}
