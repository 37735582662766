import ComponentManager from 'nms-dashboard-js/src/ComponentManager';
import DashboardComponent from 'nms-dashboard-js/src/DashboardComponent';
import CustomerJourneyTemplate from './CustomerJourney.ejs';
import Chart from 'chart.js';
import naja from 'naja';

export default class CustomerJourney extends DashboardComponent
{
	createChart()
	{
		const ctx = this._$element.find('canvas')[0].getContext('2d');
		const options = {
			layout: {
				padding: {
					top: 10
				}
			},
			maintainAspectRatio: false,
			legend: {
				display: false
			},
			scales: {
				xAxes: [{
					gridLines: {
						display: false
					},
					scaleLabel: {
						fontColor: '#8F8F8F',
						fontSize: 10
					},
					ticks: {
						maxRotation: 45,
						minRotation: 45
					}
				}],
				yAxes: [{
					ticks: {
						padding: 10,
						suggestedMax: 5,
						suggestedMin: 1,
						stepSize: 1,
						callback: function() {
							return '';
						}
					},
					scaleLabel: {
						display: false,
						fontColor: '#8F8F8F',
						fontSize: 10
					}
				}]
			},
			onClick: (e, elements) => {
				if (!elements.length) return;

				const selectedPointIndex = elements[0]._index;

				this.selectPoint(selectedPointIndex);
				this._chart.update();
			},
			animation: {
				onComplete: () => {
					// this.scrollToEnd(); this scroll event if you don't wa
				}
			}
		};

		this.decorateData();

		this._chart = new Chart(ctx, {
			type: 'line',
			data: this._data.data,
			options: options
		});

		return this._chart;
	}

	onUpdate(data)
	{
		this.setupWidthByData();

		if (!this._chart) {
			this.createChart();
		} else {
			this.decorateData();
			this._chart.data.labels = this._data.data.labels;
			this._chart.data.datasets[0] = this._data.data.datasets[0];
			this._chart.update();
		}

		this.updateSnippets();
	}



	decorateData()
	{
		if (!this._data) return;

		const dataset = this._data.data.datasets[0];
		dataset.borderColor = '#6E65AE';
		dataset.pointRadius = 7;
		dataset.pointHoverRadius = 7;
		dataset.pointBackgroundColor = '#FFFFFF';
		dataset.fill = false;
		dataset.lineTension = 0;
		dataset.spanGaps = true;
		this.setupPointsBackground(dataset);
	}

	setupWidthByData()
	{
		const $canvasWrapper = this._$element.find('[data-canvas-wrapper]');
		const eventsCount = this._data.data.datasets[0].data.length;
		const requiredWidth = eventsCount * 28;

		if ($canvasWrapper.width() < requiredWidth) {
			$canvasWrapper.width(requiredWidth);
		}
	}

	scrollToEnd()
	{
		const $chartWrapper = this._$element.find('[data-chart-wrapper]');
		const $canvasWrapper = this._$element.find('[data-canvas-wrapper]');

		$chartWrapper.scrollLeft($canvasWrapper.width()-$chartWrapper.width());
	}

	setupPointsBackground(dataset)
	{
		dataset.pointBackgroundColor = [];
		dataset.data.forEach(function() {
			dataset.pointBackgroundColor.push('#FFFFFF');
		});
	}

	selectPoint(pointIndex)
	{
		const dataset = this._chart.data.datasets[0];

		this.setupPointsBackground(dataset);
		dataset.pointBackgroundColor[pointIndex] = '#6E65AE';

		const eventDetailUrl = this._options.eventDetailUrl + '&eventId=' + this._data.data.eventIds[pointIndex];

		naja.makeRequest('GET', eventDetailUrl).then(function() {
			const $target = $('#selectedEvent');
			$('html, body').animate({scrollTop: $target.offset().top - 50}, 1000);
		});
	}

	render()
	{
		const template = this.getTemplate();
		template.setFile(CustomerJourneyTemplate);

		return template.render();
	}
}

ComponentManager.registerComponentClass('CustomerJourney', CustomerJourney);
