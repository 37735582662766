import PageTopFilter from "../../PageTopFilter";

export default class SelectProjectFilter extends PageTopFilter {
    public init (): void {
        super.init();

        // Filter projects on filter change
        this.$component.find('form.selectProjectFilter').on('change', e => {
            this.context.naja.uiHandler.submitForm(e.currentTarget);
        });

        // Clean filter
        this.$component.find('.selectProjectFilter [data-clean-filter]').on('click', e => {

            // uncheck all
            $(e.currentTarget).closest('[data-filter-component]').find('input[type="checkbox"]').prop('checked', false);

            // clear input filter
            if ($(e.currentTarget).closest('[data-filter-component]').find('input[data-target-list-filter]').length > 0) {
                $(e.currentTarget).closest('[data-filter-component]').find('input[data-target-list-filter]').val('');
                $(e.currentTarget).closest('[data-filter-component]').find('input[data-target-list-filter]').trigger('change');
            }

            // call form change
            this.context.naja.uiHandler.submitForm(<HTMLFormElement>$(e.currentTarget).closest('form')[0]);
        });
    }
}
